import React, { useEffect, useState } from "react";
import "./footer.scss";
import { useLanguage } from "../../LanguageContext";
import { Link, NavLink } from "react-router-dom";
import {
  addressURL,
  asresoftURL,
  bookingURL,
  contactURL,
  emailURL,
  homeURL,
  locationURL,
  phoneURL,
  conditionsURL,
  roomsURL,
} from "../../helpers/constant";
import { images } from "../../assets";
import { AnimatedCta } from "../AnimatedCta/AnimatedCta";

const Footer = () => {
  const { t } = useLanguage();
  const [bookingFooter, setBookingFooter] = useState(true);
  const pathName = window.location.pathname;

  useEffect(() => {
    if (pathName === bookingURL) {
      setBookingFooter(false);
    } else {
      setBookingFooter(true);
    }
  }, [pathName]);

  return (
    <>
      <footer className="footer_sec">
        <div className="footer1">
          <div className="my_container">
            <div className="footer_flex">
              <div className="fcol fcol-1">
                <h6 className="soft_title">{t("footer.col1.title")}</h6>
                <nav className="navlist">
                  <NavLink to={homeURL} className="anchor list_item">
                    {t("header.navlist.home")}
                  </NavLink>
                  <NavLink to={roomsURL} className="anchor list_item">
                    {t("header.navlist.rooms")}
                  </NavLink>
                  <NavLink to={locationURL} className="anchor list_item">
                    {t("header.navlist.location")}
                  </NavLink>
                  <NavLink to={contactURL} className="anchor list_item">
                    {t("header.navlist.contact")}
                  </NavLink>
                </nav>
              </div>
              <div className="fcol fcol-2">
                <h6 className="soft_title">{t("footer.col2.title")}</h6>
                <p className="desc">{t("footer.col2.description")}</p>
              </div>
              <div className="fcol fcol-3">
                <h6 className="soft_title">{t("footer.col3.title")}</h6>
                <ul className="connect_list">
                  <li className="list_item">
                    <h6 className="title">{t("footer.col3.address")}</h6>
                    <Link to={addressURL} className="anchor">
                      2175 route de Réauville
                      <br />
                      26230 Chantemerle-lès-Grignan
                    </Link>
                  </li>
                  <li className="list_item">
                    <h6 className="title">{t("footer.col3.phone")}</h6>
                    <Link to={phoneURL} className="anchor">
                      +33 6 36 57 25 92
                    </Link>
                  </li>
                  <li className="list_item">
                    <h6 className="title">{t("footer.col3.email")}</h6>
                    <Link to={emailURL} className="anchor">
                      masdupontdelaberre@gmail.com
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="footer2">
          <div className="my_container">
            <div className="copyright_footer">
              <div className="accredited">
                @{new Date().getFullYear()} Masdupontdeleberre
              </div>
              <div className="legals">
                <div className="list_item">
                  <Link to={conditionsURL} className="anchor">
                    {t("footer.conditions")}
                  </Link>
                </div>
              </div>
              <div className="credit">
                <Link to={asresoftURL} className="anchor">
                  {t("footer.credit")}
                </Link>
              </div>
            </div>
          </div>
        </div>
        {bookingFooter && (
          <div className="footer3">
            <img
              width={1920}
              height={480}
              src={images.footerBg.img}
              alt="footer"
              className="bg_img"
              loading="lazy"
            />
            <div className="text_container">
              <div className="cta_wrapper">
                <AnimatedCta
                  icons
                  urlText={t("home.banner.ctaText")}
                  url={bookingURL}
                />
              </div>
            </div>
          </div>
        )}
      </footer>
    </>
  );
};

export default Footer;
