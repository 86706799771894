import React, { useContext, useState,useEffect } from "react";

import SettingContext from "./SettingContext";
import http from "../../helpers/http";
import config from "../../config/config";

const SettingState = (props) => {

  const base_url = config.api_url;




  const [data, setData] = useState({});

  const update = (data) => {
    setData(data);
  };

  const fetchSettings = async () => {
    const { data } = await http.get(base_url + "/settings");

    if (data) {
      setData(data);
    }
  };

  const refresh = () => {
    fetchSettings();
  };

  useEffect(() => {
    fetchSettings();
  }, []);


  return (
    <SettingContext.Provider value={{ data, update,refresh }}>
      {props.children}
    </SettingContext.Provider>
  );
};


export const useSetting = () => {
  return useContext(SettingContext);
};

export default SettingState;
