import React from "react";
import './loader.scss';


const Loader = () => {
  return (
    <div className="loader">
      <svg
        width="746"
        height="340"
        viewBox="0 0 746 340"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="loader_svg"
      >
        <path
          d="M111.825 281.54C112.209 281.54 112.593 281.54 112.978 281.54V282.499C111.185 282.754 109.191 282.882 106.988 282.882C104.784 282.882 102.068 282.408 98.8393 281.449C95.6111 280.491 92.6939 278.711 90.0693 276.091C84.7011 270.732 81.4455 263.785 80.2932 255.241L74.4496 212.675L54.5226 257.633C52.7302 261.522 51.651 264.771 51.267 267.391H50.3067L25.4872 213.542L21.2713 261.074C21.1433 262.161 21.4816 263.119 22.2773 263.941C23.0729 264.771 23.9508 265.182 24.911 265.182H26.4474V266.04H7.85557V265.082H9.39193C11.1203 265.082 12.6293 264.552 13.9461 263.502C15.2539 262.453 16.0678 261.065 16.3879 259.34L22.4236 207.115C16.7994 196.088 10.0595 190.575 2.20395 190.575C1.49978 190.575 0.768181 190.611 0 190.675V189.716C1.72841 189.269 3.1276 189.05 4.21585 189.05C5.30411 189.05 6.48382 189.096 7.76412 189.196C9.04442 189.287 10.5442 189.598 12.2635 190.109C13.9919 190.62 15.6197 191.369 17.1469 192.355C18.6833 193.34 20.0184 194.363 21.1707 195.413C22.323 196.462 23.4021 197.631 24.4263 198.909C26.1547 201.2 27.5265 203.4 28.5507 205.509L51.6419 255.433L77.4217 197.759H78.2813L87.1886 254.383C88.5329 263.311 91.4227 270.066 95.858 274.657C100.312 279.24 105.625 281.54 111.825 281.54Z"
          fill="#282828"
          class="svg-elem-1"
        ></path>
        <path
          d="M169.987 265.082H170.755V266.041H149.191V265.082H150.051C151.395 265.082 152.401 264.653 153.069 263.795C153.737 262.937 154.075 262.124 154.075 261.358C154.075 260.591 153.947 259.824 153.691 259.066L147.555 242.808H121.683L115.263 259.167C115.007 259.934 114.879 260.682 114.879 261.412C114.879 262.143 115.218 262.946 115.885 263.804C116.553 264.662 117.559 265.091 118.903 265.091H119.763V266.05H100.503V265.091H101.363C102.963 265.091 104.527 264.58 106.054 263.558C107.591 262.535 108.835 261.038 109.795 259.066L131.45 207.609C133.81 202.442 134.998 199.165 134.998 197.759H135.858L161.537 259.066C162.433 261.047 163.65 262.544 165.177 263.558C166.732 264.571 168.323 265.082 169.987 265.082ZM122.845 239.933H146.421L134.925 209.224L122.845 239.933Z"
          fill="#282828"
          class="svg-elem-2"
        ></path>
        <path
          d="M181.199 262.115C181.007 261.221 180.916 259.505 180.916 256.949C180.916 254.402 181.391 251.69 182.351 248.815H183.211C183.147 249.518 183.119 250.221 183.119 250.924C183.119 254.812 184.299 258.053 186.668 260.627C189.027 263.211 192.164 264.498 196.06 264.498C200.083 264.498 203.394 263.284 205.973 260.865C208.561 258.446 209.85 255.397 209.85 251.727C209.85 248.057 208.607 245.081 206.11 242.781L187.71 225.756C184.263 222.57 182.534 218.709 182.534 214.181C182.534 209.654 184.052 205.783 187.088 202.561C190.125 199.338 194.231 197.732 199.398 197.732C201.821 197.732 204.574 198.051 207.637 198.69H211.378V210.457H210.518C210.518 207.463 209.475 205.071 207.399 203.282C205.324 201.493 202.653 200.607 199.398 200.607C196.142 200.607 193.408 201.693 191.204 203.857C189 206.02 187.902 208.732 187.902 211.99C187.902 215.24 189.183 218.052 191.734 220.407L210.609 237.815C213.801 240.745 215.401 244.762 215.401 249.865C215.401 254.968 213.774 259.158 210.518 262.444C207.262 265.73 202.918 267.373 197.486 267.373C194.679 267.373 191.688 266.944 188.524 266.086C185.36 265.228 182.918 263.904 181.199 262.115Z"
          fill="#282828"
          class="svg-elem-3"
        ></path>
        <path
          d="M319.509 208.32C325.864 214.473 329.047 222.561 329.047 232.566C329.047 242.571 325.864 250.659 319.509 256.811C313.153 262.964 304.858 266.04 294.643 266.04H262.16V265.082H263.404C265.068 265.082 266.486 264.488 267.665 263.311C268.845 262.133 269.467 260.709 269.531 259.057V205.491C269.531 203.957 268.909 202.67 267.665 201.62C266.422 200.57 264.995 200.041 263.404 200.041H262.252L262.16 199.082H294.643C304.867 199.091 313.153 202.168 319.509 208.32ZM275.768 201.958V263.174H294.643C300.011 263.174 304.748 261.896 308.873 259.349C312.997 256.802 316.189 253.215 318.457 248.586C320.725 243.967 321.859 238.627 321.859 232.566C321.859 223.382 319.353 215.988 314.342 210.374C309.33 204.76 302.764 201.958 294.652 201.958H275.768Z"
          fill="#282828"
          class="svg-elem-4"
        ></path>
        <path
          d="M408.48 200.041H406.944C405.6 200.041 404.438 200.47 403.451 201.328C402.463 202.186 401.933 203.254 401.869 204.532V242.407C401.869 249.865 399.5 255.89 394.781 260.481C390.053 265.073 383.761 267.364 375.906 267.364C368.05 267.364 361.676 265.082 356.793 260.527C351.909 255.972 349.459 249.956 349.459 242.498V204.815C349.459 202.908 348.626 201.502 346.971 200.607C346.203 200.224 345.343 200.032 344.383 200.032H342.847V199.074H362.298V200.032H360.762C359.353 200.032 358.174 200.461 357.213 201.319C356.253 202.177 355.741 203.282 355.677 204.624V241.156C355.677 248.112 357.543 253.717 361.283 257.989C365.023 262.261 370.017 264.397 376.281 264.397C382.536 264.397 387.621 262.371 391.516 258.327C395.412 254.274 397.36 248.97 397.36 242.398V204.615C397.296 203.273 396.784 202.177 395.824 201.31C394.864 200.443 393.684 200.023 392.276 200.023H390.739V199.064H408.462V200.041H408.48Z"
          fill="#282828"
          class="svg-elem-5"
        ></path>
        <path
          d="M482.454 197.559C488.015 197.559 492.642 198.9 496.346 201.575C501.33 205.144 503.817 210.722 503.817 218.317C503.817 223.803 502.281 228.486 499.217 232.375C495.898 236.519 491.261 238.591 485.326 238.591C482.198 238.591 479.354 237.843 476.794 236.346C474.242 234.849 472.733 232.284 472.294 228.65L473.154 228.367C473.858 231.106 475.486 233.023 478.037 234.109C479.757 234.876 481.293 235.259 482.637 235.259C487.877 235.259 491.673 233.215 494.041 229.134C495.77 226.14 496.629 222.571 496.629 218.417C496.629 211.79 494.773 207.007 491.069 204.067C488.262 201.767 484.677 200.626 480.333 200.626C479.309 200.626 478.431 200.671 477.699 200.772C476.967 200.863 473.758 201.393 468.069 202.351V259.067C468.133 260.792 468.773 262.225 469.981 263.375C471.197 264.526 472.596 265.101 474.197 265.101H475.44V266.059H454.452V265.101H455.696C457.297 265.101 458.696 264.526 459.912 263.375C461.128 262.225 461.768 260.792 461.832 259.067V207.317C461.832 205.665 461.348 204.368 460.397 203.446C459.436 202.525 458.193 202.059 456.656 202.059C456.336 202.059 456.016 202.095 455.696 202.15L454.544 202.242L454.352 201.283C457.671 200.964 462.125 200.343 467.722 199.421C473.309 198.499 476.775 197.951 478.12 197.796C479.482 197.641 480.918 197.559 482.454 197.559Z"
          fill="#282828"
          class="svg-elem-6"
        ></path>
        <path
          d="M528.92 207.362C535.752 200.954 544.613 197.75 555.514 197.75C566.406 197.75 575.267 200.954 582.108 207.362C588.939 213.77 592.359 222.168 592.359 232.566C592.359 242.963 588.939 251.361 582.108 257.77C575.276 264.178 566.406 267.382 555.514 267.382C544.622 267.382 535.761 264.178 528.92 257.77C522.08 251.361 518.669 242.963 518.669 232.566C518.669 222.168 522.089 213.77 528.92 207.362ZM534.142 255.707C539.538 261.576 546.643 264.507 555.459 264.507C564.275 264.507 571.381 261.576 576.776 255.707C582.172 249.837 584.869 242.123 584.869 232.557C584.869 222.99 582.172 215.276 576.776 209.407C571.381 203.537 564.275 200.607 555.459 200.607C546.643 200.607 539.538 203.537 534.142 209.407C528.747 215.276 526.049 222.99 526.049 232.557C526.049 242.132 528.747 249.846 534.142 255.707Z"
          fill="#282828"
          class="svg-elem-7"
        ></path>
        <path
          d="M676.393 200.042C674.792 200.042 673.421 200.598 672.278 201.712C671.125 202.826 670.522 204.186 670.458 205.774V255.132C670.458 258.829 670.677 262.335 671.125 265.658L671.317 267.383H670.458L618.24 211.717V259.35C618.24 260.947 618.816 262.298 619.968 263.412C621.12 264.526 622.492 265.082 624.083 265.082H625.903V266.041H606.168V265.082H607.988C609.588 265.082 610.96 264.526 612.112 263.412C613.265 262.298 613.841 260.911 613.841 259.249V209.608C613.841 205.72 613.612 202.242 613.173 199.183L612.981 197.65H613.941L666.159 253.316V205.875C666.095 204.213 665.492 202.826 664.34 201.712C663.187 200.598 661.816 200.042 660.224 200.042H658.405V199.083H678.139V200.042H676.393Z"
          fill="#282828"
          class="svg-elem-8"
        ></path>
        <path
          d="M744.615 207.599C744.615 206.066 744.075 204.742 742.987 203.628C741.899 202.515 740.618 201.921 739.155 201.857H720.947V259.148C721.011 260.809 721.633 262.206 722.813 263.356C723.993 264.506 725.419 265.082 727.074 265.082H728.318V266.04H707.33V265.082H708.574C710.238 265.082 711.656 264.506 712.836 263.356C714.015 262.206 714.637 260.837 714.701 259.239V201.848H696.493C694.957 201.912 693.668 202.505 692.616 203.619C691.564 204.733 691.034 206.056 691.034 207.59V209.215L690.074 209.315V197.266C691.034 197.649 692.689 198.032 695.058 198.416C697.417 198.799 699.594 198.991 701.569 198.991H733.961C737.088 198.991 739.695 198.754 741.77 198.27C743.846 197.795 745.108 197.457 745.557 197.266V209.315L744.596 209.215V207.599H744.615Z"
          fill="#282828"
          class="svg-elem-9"
        ></path>
        <path
          d="M210.811 302.034C214.899 305.986 216.938 311.19 216.938 317.625C216.938 324.061 214.899 329.255 210.811 333.217C206.723 337.169 201.401 339.15 194.825 339.15H173.947V338.539H174.752C175.822 338.539 176.736 338.164 177.496 337.398C178.255 336.64 178.657 335.727 178.694 334.659V300.226C178.694 299.24 178.291 298.41 177.496 297.734C176.691 297.059 175.776 296.721 174.752 296.721H174.011L173.947 296.109H194.825C201.401 296.1 206.732 298.072 210.811 302.034ZM182.699 297.944V337.297H194.834C198.282 337.297 201.337 336.476 203.979 334.842C206.632 333.208 208.68 330.898 210.143 327.922C211.597 324.946 212.329 321.514 212.329 317.625C212.329 311.719 210.719 306.972 207.491 303.357C204.263 299.751 200.047 297.944 194.834 297.944H182.699Z"
          fill="#282828"
          class="svg-elem-10"
        ></path>
        <path
          d="M225.69 338.52C226.76 338.52 227.674 338.146 228.433 337.379C229.192 336.621 229.595 335.708 229.631 334.64V300.208C229.631 299.222 229.238 298.391 228.461 297.716C227.683 297.04 226.76 296.702 225.69 296.702H224.949L224.885 296.091H242.937C244.208 296.091 245.598 295.972 247.098 295.725C248.598 295.479 249.659 295.233 250.272 294.986V302.736H249.659V301.632C249.659 300.646 249.32 299.806 248.644 299.112C247.967 298.418 247.153 298.044 246.211 298.008H233.646V317.004H244.053C244.876 316.968 245.571 316.657 246.147 316.082C246.723 315.507 247.007 314.795 247.007 313.928V313.07H247.62V322.782H247.007V321.861C247.007 321.039 246.732 320.345 246.175 319.77C245.617 319.195 244.931 318.885 244.108 318.848H233.637V337.233H242.443C245.48 337.233 247.802 336.557 249.403 335.206C251.003 333.855 252.338 331.847 253.408 329.182H254.021L250.445 339.141H224.885V338.529H225.69V338.52Z"
          fill="#282828"
          class="svg-elem-11"
        ></path>
        <path
          d="M281.557 338.521C282.627 338.521 283.541 338.146 284.3 337.38C285.059 336.622 285.462 335.709 285.498 334.641V300.573C285.452 299.505 285.059 298.592 284.3 297.835C283.541 297.077 282.627 296.694 281.557 296.694H280.752V296.082H294.241V296.694H293.436C291.954 296.694 290.83 297.333 290.052 298.601C289.687 299.213 289.494 299.87 289.494 300.573V337.224H298.301C301.337 337.224 303.66 336.549 305.26 335.198C306.861 333.847 308.196 331.839 309.266 329.173H309.879L306.303 339.132H280.743V338.521H281.557Z"
          fill="#282828"
          class="svg-elem-12"
        ></path>
        <path
          d="M358.247 338.52H358.741V339.131H344.877V338.52H345.435C346.294 338.52 346.944 338.246 347.373 337.689C347.803 337.132 348.023 336.612 348.023 336.119C348.023 335.626 347.94 335.133 347.776 334.64L343.834 324.188H327.209L323.084 334.704C322.92 335.197 322.837 335.681 322.837 336.146C322.837 336.621 323.057 337.132 323.487 337.68C323.916 338.237 324.566 338.511 325.425 338.511H325.983V339.122H313.601V338.511H314.159C315.183 338.511 316.189 338.182 317.177 337.525C318.164 336.867 318.96 335.909 319.582 334.631L333.5 301.549C335.018 298.226 335.777 296.118 335.777 295.214H336.335L352.842 334.631C353.418 335.9 354.196 336.867 355.183 337.525C356.153 338.191 357.186 338.52 358.247 338.52ZM327.94 322.353H343.094L335.704 302.617L327.94 322.353Z"
          fill="#282828"
          class="svg-elem-13"
        ></path>
        <path
          d="M402.719 312.33C401.978 313.106 401.137 313.681 400.195 314.055C404.219 314.631 407.282 316.055 409.376 318.328C411.471 320.601 412.513 323.385 412.513 326.662C412.513 330.642 410.913 333.855 407.712 336.311C404.511 338.766 400.295 339.999 395.083 339.999C389.87 339.999 385.782 338.721 382.828 336.156C379.875 333.591 378.393 329.976 378.393 325.302C378.393 325.055 378.393 324.809 378.393 324.562L379.07 324.444C379.893 330.551 382.435 334.513 386.706 336.311V300.527C386.624 299.422 386.203 298.51 385.444 297.788C384.685 297.067 383.77 296.711 382.7 296.711H381.96V296.1H392.312C396.171 296.1 399.262 296.985 401.585 298.747C403.908 300.509 405.069 302.818 405.069 305.666C405.06 308.505 404.283 310.733 402.719 312.33ZM390.711 298.062V337.415C392.065 337.616 393.629 337.726 395.394 337.726C399.335 337.726 402.408 336.667 404.603 334.558C406.797 332.449 407.895 329.711 407.895 326.352C407.895 324.544 407.557 322.947 406.88 321.559C406.203 320.162 405.353 319.076 404.328 318.3C401.621 316.21 397.799 315.169 392.87 315.169H392.806L392.742 314.612H392.806C394.9 314.612 396.692 313.928 398.192 312.549C399.692 311.18 400.442 309.053 400.442 306.187C400.442 300.773 397.46 298.071 391.507 298.071H390.711V298.062Z"
          fill="#282828"
          class="svg-elem-14"
        ></path>
        <path
          d="M421.265 338.52C422.335 338.52 423.249 338.146 424.008 337.379C424.768 336.621 425.17 335.708 425.206 334.64V300.208C425.206 299.222 424.813 298.391 424.036 297.716C423.259 297.04 422.335 296.702 421.265 296.702H420.524L420.46 296.091H438.512C439.784 296.091 441.174 295.972 442.673 295.725C444.173 295.479 445.225 295.233 445.847 294.986V302.736H445.234V301.632C445.234 300.646 444.896 299.806 444.219 299.112C443.542 298.418 442.728 298.044 441.786 298.008H429.221V317.004H439.628C440.451 316.968 441.146 316.657 441.722 316.082C442.299 315.507 442.582 314.795 442.582 313.928V313.07H443.195V322.782H442.582V321.861C442.582 321.039 442.308 320.345 441.75 319.77C441.192 319.195 440.506 318.885 439.683 318.848H429.212V337.233H438.019C441.055 337.233 443.378 336.557 444.978 335.206C446.578 333.855 447.914 331.847 448.984 329.182H449.596L446.021 339.141H420.46V338.529H421.265V338.52Z"
          fill="#282828"
          class="svg-elem-15"
        ></path>
        <path
          d="M470.538 297.998H466.597V334.64C466.597 335.708 466.981 336.621 467.74 337.379C468.499 338.137 469.432 338.52 470.547 338.52H471.288L471.352 339.132H457.863V338.52H458.668C459.738 338.52 460.652 338.155 461.412 337.415C462.171 336.676 462.573 335.772 462.61 334.713V300.591C462.564 299.523 462.171 298.61 461.412 297.852C460.652 297.095 459.738 296.711 458.668 296.711H457.863V296.1H474.498C478.238 296.1 481.174 297.131 483.305 299.203C485.435 301.275 486.505 303.959 486.505 307.255C486.505 310.559 485.49 313.407 483.46 315.799C481.43 318.2 478.952 319.396 476.034 319.396C476.647 319.688 477.379 320.245 478.22 321.084C479.061 321.924 479.811 322.801 480.47 323.695C484 328.734 486.304 331.847 487.365 333.006C488.435 334.175 489.194 334.978 489.642 335.407C490.09 335.836 490.584 336.256 491.124 336.667C491.654 337.078 492.194 337.406 492.724 337.653C493.913 338.228 495.184 338.511 496.547 338.511V339.122H493.154C491.389 339.122 489.743 338.803 488.225 338.173C486.707 337.534 485.564 336.886 484.804 336.201C484.045 335.526 483.387 334.869 482.829 334.23C482.271 333.591 480.744 331.473 478.238 327.867C475.733 324.261 474.279 322.198 473.867 321.687C473.456 321.176 473.026 320.674 472.568 320.181C471.252 318.784 469.944 318.09 468.627 318.09V317.479C468.956 317.515 469.386 317.543 469.916 317.543C470.447 317.543 471.105 317.524 471.892 317.479C476.986 317.36 480.186 315.224 481.503 311.08C481.75 310.258 481.869 309.491 481.869 308.77C481.869 308.049 481.869 307.592 481.869 307.382C481.787 304.635 481.064 302.426 479.711 300.746C478.357 299.066 476.528 298.163 474.233 298.044C473.254 298.026 472.02 297.998 470.538 297.998Z"
          fill="#282828"
          class="svg-elem-16"
        ></path>
        <path
          d="M512.862 297.998H508.92V334.64C508.92 335.708 509.304 336.621 510.063 337.379C510.822 338.137 511.755 338.52 512.871 338.52H513.611L513.675 339.132H500.187V338.52H500.991C502.061 338.52 502.976 338.155 503.735 337.415C504.494 336.676 504.896 335.772 504.933 334.713V300.591C504.887 299.523 504.494 298.61 503.735 297.852C502.976 297.095 502.061 296.711 500.991 296.711H500.187V296.1H516.821C520.562 296.1 523.497 297.131 525.628 299.203C527.759 301.275 528.829 303.959 528.829 307.255C528.829 310.559 527.814 313.407 525.783 315.799C523.753 318.2 521.275 319.396 518.358 319.396C518.97 319.688 519.702 320.245 520.543 321.084C521.385 321.924 522.135 322.801 522.793 323.695C526.323 328.734 528.628 331.847 529.688 333.006C530.758 334.175 531.517 334.978 531.965 335.407C532.414 335.836 532.907 336.256 533.447 336.667C533.977 337.078 534.517 337.406 535.047 337.653C536.236 338.228 537.507 338.511 538.87 338.511V339.122H535.477C533.712 339.122 532.066 338.803 530.548 338.173C529.03 337.534 527.887 336.886 527.128 336.201C526.369 335.526 525.71 334.869 525.152 334.23C524.595 333.591 523.067 331.473 520.562 327.867C518.056 324.261 516.602 322.198 516.19 321.687C515.779 321.176 515.349 320.674 514.892 320.181C513.575 318.784 512.267 318.09 510.95 318.09V317.479C511.279 317.515 511.709 317.543 512.24 317.543C512.77 317.543 513.428 317.524 514.215 317.479C519.309 317.36 522.509 315.224 523.826 311.08C524.073 310.258 524.192 309.491 524.192 308.77C524.192 308.049 524.192 307.592 524.192 307.382C524.11 304.635 523.387 302.426 522.034 300.746C520.681 299.066 518.851 298.163 516.556 298.044C515.568 298.026 514.334 297.998 512.862 297.998Z"
          fill="#282828"
          class="svg-elem-17"
        ></path>
        <path
          d="M543.287 338.52C544.357 338.52 545.271 338.146 546.03 337.379C546.789 336.621 547.192 335.708 547.228 334.64V300.208C547.228 299.222 546.835 298.391 546.058 297.716C545.281 297.04 544.357 296.702 543.287 296.702H542.546L542.482 296.091H560.534C561.806 296.091 563.196 295.972 564.695 295.725C566.195 295.479 567.247 295.233 567.869 294.986V302.736H567.256V301.632C567.256 300.646 566.918 299.806 566.241 299.112C565.564 298.418 564.75 298.044 563.808 298.008H551.243V317.004H561.65C562.473 316.968 563.168 316.657 563.744 316.082C564.32 315.507 564.604 314.795 564.604 313.928V313.07H565.217V322.782H564.604V321.861C564.604 321.039 564.33 320.345 563.772 319.77C563.214 319.195 562.528 318.885 561.705 318.848H551.234V337.233H560.041C563.077 337.233 565.4 336.557 567 335.206C568.6 333.855 569.936 331.847 571.005 329.182H571.618L568.043 339.141H542.482V338.529H543.287V338.52Z"
          fill="#282828"
          class="svg-elem-18"
        ></path>
        <path
          d="M432.102 62.1357C431.544 61.9622 431.041 61.6519 430.502 61.4419C419.071 56.9781 407.639 52.5142 396.208 48.0595C380.927 42.1077 365.627 36.1741 350.364 30.1675C348.663 29.5012 347.218 29.5194 345.545 30.2041C340.661 32.2306 335.723 34.1476 330.785 36.0737C320.314 40.1542 309.833 44.189 299.362 48.2786C282.161 55.0063 264.959 61.734 247.775 68.5074C243.587 70.1505 243.148 69.2012 245.251 74.0028C245.434 74.4136 245.626 74.8335 245.718 75.2716C246.047 76.8052 246.806 76.9878 248.233 76.4218C257.789 72.6518 267.373 68.9638 276.93 65.2211C279.582 64.1896 279.572 64.1348 279.572 66.9647C279.582 89.1562 279.582 111.348 279.572 133.539C279.572 136.588 285.471 134.781 282.325 134.781L287.071 136.597V134.945C287.062 134.653 287.062 134.306 287.062 133.904C287.062 110.49 287.081 87.0748 287.026 63.6601C287.026 61.9166 287.547 61.0129 289.22 60.3647C306.88 53.5549 324.584 46.8728 342.079 39.6703C346.605 37.8081 350.263 38.1002 354.525 39.8438C366.962 44.9193 379.518 49.7117 392.02 54.6046C395.815 56.0926 399.592 57.5805 403.414 58.9955C404.768 59.4975 405.271 60.3008 405.262 61.7432C405.234 85.9977 405.225 101.562 405.271 125.816C405.271 125.981 405.262 126.127 405.252 126.264H405.271V127.943H414.946C413.218 127.943 412.66 127.295 412.687 125.579C412.76 120.221 412.715 123.552 412.715 118.194V65.3581C412.715 62.6834 412.724 62.6834 415.275 63.6784C418.65 64.9929 421.997 66.3713 425.39 67.6037C427.118 68.2335 428.975 69.9041 430.575 69.1099C432.12 68.334 432.075 65.8327 432.907 64.1805C433.437 63.1307 433.264 62.4917 432.102 62.1357Z"
          fill="#A67B5B"
          class="svg-elem-19"
        ></path>
        <path
          d="M276.326 38.1182C266.038 38.0635 258.027 30.8154 257.222 20.6371C256.335 9.48202 264.108 0.755139 275.11 0.0431128C286.001 -0.668913 294.296 7.53764 295.247 17.3234C296.28 28.0769 287.684 38.1091 276.326 38.1182Z"
          fill="#F9BD3A"
          class="svg-elem-20"
        ></path>
        <path
          d="M320.003 81.8529C320.067 83.5691 319.372 84.1442 317.726 84.062C315.851 83.9616 313.976 84.0072 312.101 84.0438C310.821 84.0711 310.199 83.5965 310.208 82.2454C310.245 76.3119 310.245 70.3783 310.218 64.4448C310.208 63.1759 310.812 62.7286 311.992 62.7378C314.058 62.7651 316.125 62.7834 318.192 62.7378C319.564 62.7012 320.003 63.3676 319.984 64.6365C319.948 67.5394 319.975 70.4422 319.975 73.3451C319.975 76.175 319.902 79.0231 320.003 81.8529Z"
          fill="#A67B5B"
          class="svg-elem-21"
        ></path>
        <path
          d="M340.149 82.1993C340.167 83.5138 339.628 84.0798 338.311 84.0433C336.244 83.9976 334.177 84.0068 332.111 84.0341C330.876 84.0524 330.373 83.4956 330.382 82.2815C330.4 76.3479 330.4 70.4144 330.373 64.4808C330.373 63.2485 330.903 62.7281 332.12 62.7373C334.187 62.7646 336.253 62.7829 338.32 62.7373C339.637 62.7099 340.167 63.285 340.149 64.5995C340.103 67.5571 340.14 70.5239 340.14 73.4907C340.14 76.3936 340.103 79.2964 340.149 82.1993Z"
          fill="#A67B5B"
          class="svg-elem-22"
        ></path>
        <path
          d="M380.469 82.2359C380.479 83.4135 380.067 84.0525 378.796 84.0343C376.674 84.0069 374.543 84.016 372.422 84.0434C371.205 84.0525 370.675 83.5231 370.684 82.2907C370.721 79.3331 370.693 76.3754 370.693 73.4178C370.693 70.5879 370.748 67.7581 370.675 64.9374C370.629 63.4038 371.114 62.6552 372.751 62.7283C374.745 62.8104 376.747 62.7739 378.741 62.7374C379.976 62.7191 380.469 63.276 380.469 64.4901C380.442 70.4054 380.451 76.3207 380.469 82.2359Z"
          fill="#A67B5B"
          class="svg-elem-23"
        ></path>
        <path
          d="M360.277 82.382C360.286 83.66 359.637 84.0617 358.466 84.0434C356.464 84.016 354.461 83.9978 352.467 84.0526C351.068 84.0891 350.483 83.5322 350.519 82.1082C350.574 79.1414 350.538 76.1838 350.538 73.217C350.529 70.378 350.574 67.5482 350.51 64.7092C350.483 63.3216 350.995 62.6918 352.422 62.7374C354.479 62.7922 356.546 62.7648 358.613 62.7557C359.738 62.7557 360.268 63.2943 360.268 64.4079C360.268 70.3963 360.259 76.3937 360.277 82.382Z"
          fill="#A67B5B"
          class="svg-elem-24"
        ></path>
        <path
          d="M380.469 101.041C380.442 106.902 380.442 112.771 380.469 118.632C380.479 119.956 379.912 120.494 378.604 120.467C376.61 120.43 374.608 120.421 372.605 120.476C371.178 120.512 370.657 119.883 370.684 118.504C370.73 115.537 370.693 112.571 370.693 109.604C370.693 109.604 370.693 109.604 370.684 109.604C370.684 106.774 370.748 103.935 370.666 101.105C370.62 99.7906 370.913 99.2338 372.376 99.3342C374.434 99.4803 376.51 99.462 378.567 99.3525C379.893 99.2886 380.479 99.672 380.469 101.041Z"
          fill="#A67B5B"
          class="svg-elem-25"
        ></path>
        <path
          d="M319.984 118.532C320.012 119.928 319.454 120.531 318.036 120.485C316.098 120.421 314.159 120.43 312.22 120.476C310.867 120.513 310.199 120.047 310.208 118.596C310.245 112.726 310.236 106.856 310.208 100.996C310.199 99.7086 310.748 99.3252 311.982 99.3708C314.049 99.453 316.116 99.4804 318.183 99.3434C319.609 99.2613 320.003 99.8546 319.984 101.133C319.948 104.026 319.975 106.929 319.975 109.832C319.975 112.735 319.939 115.638 319.984 118.532Z"
          fill="#A67B5B"
          class="svg-elem-26"
        ></path>
        <path
          d="M360.268 100.859C360.259 106.856 360.259 112.845 360.268 118.842C360.268 119.974 359.738 120.476 358.613 120.467C356.482 120.439 354.351 120.439 352.22 120.458C350.968 120.476 350.51 119.855 350.529 118.669C350.556 115.702 350.538 112.735 350.538 109.768C350.538 106.874 350.556 103.972 350.519 101.069C350.51 99.8729 350.894 99.316 352.211 99.3708C354.333 99.4621 356.473 99.4438 358.594 99.3525C359.774 99.2978 360.277 99.6994 360.268 100.859Z"
          fill="#A67B5B"
          class="svg-elem-27"
        ></path>
        <path
          d="M340.158 118.376C340.186 119.828 339.701 120.54 338.155 120.485C336.153 120.412 334.15 120.439 332.147 120.467C330.949 120.485 330.373 119.974 330.382 118.741C330.391 112.817 330.4 106.883 330.373 100.95C330.373 99.8179 330.775 99.3341 331.973 99.3706C334.168 99.4436 336.372 99.4436 338.558 99.3706C339.783 99.325 340.149 99.8635 340.14 100.977C340.112 103.935 340.131 106.902 340.131 109.868C340.131 109.868 340.131 109.868 340.14 109.868C340.14 112.707 340.094 115.546 340.158 118.376Z"
          fill="#A67B5B"
          class="svg-elem-28"
        ></path>
        <path
          d="M468.646 136.615V110.544C461.604 109.723 455.303 106.153 450.593 100.813C445.289 94.7975 442.006 86.5088 442.006 77.3802C442.006 67.0741 444.274 47.2013 450.712 32.9334C455.321 22.7277 462.089 15.2332 471.773 15.2332C481.467 15.2332 488.234 22.7368 492.834 32.9334C499.272 47.1922 501.54 67.065 501.54 77.3802C501.54 86.5088 498.257 94.7975 492.953 100.813C488.243 106.153 481.942 109.723 474.901 110.544V136.615M461.458 90.5071C458.211 88.0059 462.025 83.0673 465.28 85.5685L468.984 88.4075V68.1695C468.984 64.0526 475.248 64.0526 475.248 68.1695V77.3711L477.882 74.523C480.662 71.5015 485.271 75.728 482.482 78.7495L475.239 86.5818V104.191C480.269 103.333 484.787 100.631 488.252 96.6962C492.587 91.7759 495.267 84.9569 495.267 77.3985C495.267 67.631 493.145 48.8627 487.118 35.5168C483.47 27.438 478.458 21.5045 471.764 21.5045C465.07 21.5045 460.049 27.438 456.41 35.5168C450.392 48.8536 448.261 67.631 448.261 77.3985C448.261 84.9569 450.941 91.7851 455.276 96.6962C458.888 100.795 463.662 103.57 468.966 104.291V96.2946L461.458 90.5071Z"
          fill="#A67B5B"
          class="svg-elem-29"
        ></path>
        <path
          d="M244.053 124.292C248.69 124.502 253.043 124.648 257.377 124.912C268.443 125.597 278.667 128.098 288.498 131.366C296.893 134.16 305.48 136.588 314.881 137.437C333.61 139.116 351.754 138.103 368.855 132.243C377.067 129.431 385.49 126.884 394.672 125.761C417.507 122.968 439.564 124.173 460.452 131.449C469.478 134.589 478.934 136.99 489.204 137.665C493.255 137.93 497.315 138.067 501.549 138.268C501.549 140.276 501.549 142.33 501.549 144.612C496.895 144.384 492.359 144.238 487.841 143.927C476.922 143.179 466.844 140.668 457.114 137.437C449.661 134.963 442.052 132.708 433.748 131.777C413.73 129.55 394.388 130.581 376.089 136.926C368.27 139.637 360.195 142.001 351.452 143.087C329.157 145.853 307.52 144.867 286.962 137.939C279.865 135.547 272.65 133.329 264.812 132.151C258.557 131.211 252.22 130.746 245.818 130.782C244.291 130.791 243.971 130.435 244.017 129.532C244.117 127.898 244.053 126.254 244.053 124.292Z"
          fill="#A67B5B"
          class="svg-elem-30"
        ></path>
        <path
          d="M244.053 144.62C248.69 144.83 253.043 144.976 257.377 145.241C268.443 145.926 278.667 148.427 288.498 151.695C296.893 154.488 305.48 156.917 314.881 157.765C333.61 159.445 351.754 158.432 368.855 152.571C377.067 149.76 385.49 147.213 394.672 146.09C417.507 143.297 439.564 144.502 460.452 151.777C469.478 154.917 478.934 157.318 489.204 157.994C493.255 158.258 497.315 158.395 501.549 158.596C501.549 160.604 501.549 162.658 501.549 164.94C496.895 164.712 492.359 164.566 487.841 164.256C476.922 163.507 466.844 160.997 457.114 157.765C449.661 155.292 442.052 153.037 433.748 152.106C413.73 149.878 394.388 150.91 376.089 157.254C368.27 159.965 360.195 162.33 351.452 163.416C329.157 166.182 307.52 165.196 286.962 158.268C279.865 155.876 272.65 153.658 264.812 152.48C258.557 151.54 252.22 151.074 245.818 151.111C244.291 151.12 243.971 150.764 244.017 149.86C244.117 148.217 244.053 146.583 244.053 144.62Z"
          fill="#A67B5B"
          class="svg-elem-31"
        ></path>
      </svg>
    </div>
  );
};

export default Loader;
