import React, { useState, useEffect } from "react";
import { images } from "../../../assets";
import { DatePicker } from "antd";
import { useLanguage } from "../../../LanguageContext";

import moment from "moment";
import dayjs from "dayjs";

import isSameOrBefore from "dayjs/plugin/isSameOrBefore"; // ES 2015

import config from "../../../config/config";
import http from "../../../helpers/http";
import Joi from "joi";
import { useBooking } from "../../../context/Booking/BookingState";
import { useSetting } from "../../../context/Setting/SettingState";

import FR_errors from "../../../joiLocales/validations_fr";

const { RangePicker } = DatePicker;

dayjs.extend(isSameOrBefore);
const Step1 = ({ currentStep, nextStep, onStepChange }) => {
  // =============== VARIABLE & STATES  ===========================
  const [values, setValues] = useState();
  const [formError, setFormError] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    date: "",
    adult: "",
  });
  const [adults, setAdults] = useState(10);

  const [dates, setDates] = useState([]);
  const [blockDates, setBlockDates] = useState([]);

  const { t, i18n } = useLanguage();

  const disabledDates = [
    "2024-10-30T00:00:00.000Z",
    "2024-11-01",
    "2024-11-15", // Add more dates in YYYY-MM-DD format
  ];

  const base_url = config.api_url + "/booking";

  const Booking = useBooking();
  const Setting = useSetting();

  const block_dates = Setting.data.block_dates;

  const currentLang = i18n.resolvedLanguage;

  const joiOptions = {
    abortEarly: false,
    allowUnknown: true,
    errors: {
      wrap: {
        label: "",
      },
      language: currentLang,
    },

    messages: {
      fr: { ...FR_errors },
    },
  };
  // =============== FUNCTIONS  ===========================

  const handleAdultReduce = () => {
    setAdults((prev) => (prev > 10 ? prev - 1 : 10));
    // if (adults === 10) {
    //   setError((prev) => ({
    //     ...prev,
    //     adult: t("booking.step1.error.minAdult"),
    //   }));
    // } else {
    //   setError((prev) => ({ ...prev, adult: "" }));
    // }
  };
  const handleAdultAdd = () => {
    setAdults((prev) => (prev < 21 ? prev + 1 : 21));
    // if (adults === 21) {
    //   setError((prev) => ({
    //     ...prev,
    //     adult: t("booking.step1.error.maxAdult"),
    //   }));
    // } else {
    //   setError((prev) => ({ ...prev, adult: "" }));
    // }
  };

  const disabledDate = (current) => {
    // Convert each date in the array to the same format as current (dayjs object)
    const isCustomDateDisabled = block_dates.some((date) =>
      dayjs(date).isSame(current, "day")
    );
    // console.log({current:dayjs().endOf("day")})
    // Disable dates before today and custom dates from the array
    // return (current && current <= dayjs().endOf("day")) ;
    return (current.isBefore(dayjs().add(3, 'day'),'date'))  || isCustomDateDisabled;
  };

  const handleChange = (e) => {
    setValues((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  function getDatesInRange(startDate, endDate) {
    const dates = [];
    let currentDate = dayjs(startDate);

    while (currentDate.isSameOrBefore(dayjs(endDate))) {
      dates.push(currentDate.format("YYYY-MM-DD"));
      currentDate = currentDate.add(1, "day"); // Add one day
    }

    return dates;
  }
  const validate = async (data) => {
    let schemaObj = {
      start_date: Joi.string().required().label(t("booking.step1.startDate")),
      end_date: Joi.string().required().label(t("booking.step1.endDate")),
      guest: Joi.number().required().label("Guest"),
    };

    const schema = Joi.object(schemaObj).options({ allowUnknown: true });

    const { error } = schema.validate(data, joiOptions);

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    const selectedDateRange = getDatesInRange(data.start_date, data.end_date);

    const selectedBlockDates = selectedDateRange.filter((value) =>
      block_dates.includes(value)
    );

    console.log({ selectedBlockDates });
    if (selectedBlockDates.length > 0) {
      errors["start_date"] = t("validations.blockDateSelected");
    }

    return errors ? errors : null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let form_values = {
      start_date: dates[0],
      end_date: dates[1],
      guest: adults,
    };

    let errors = await validate(form_values);

    console.log({ errors });

    setFormError(errors);
    if (Object.keys(errors).length) {
      return;
    }
    let status = currentStep + 1;

    Booking.update({ ...Booking.data, ...form_values, status: status });

    nextStep();
    onStepChange(currentStep);
    window.scrollTo(0, 0);
  };

  // =============== HTML RENDERING  ===========================
  // =============== DATA FETCHING  ===========================

  const fetchBlockDates = async () => {
    setLoading(true);

    const { data } = await http.get(base_url + "/block-dates");

    if (data) {
      setBlockDates(data);
    }

    setLoading(false);
  };

  // =============== SIDE EFFECTS  ===========================

  useEffect(() => {
    // console.log({currentLang})
    // fetchBlockDates();
    console.log({ today: dayjs() });
    console.log({ date: dayjs("2024-11-29") });
    console.log({ compare: dayjs("2024-11-29").isBefore(dayjs(), "date") });
  }, []);

  // if (loading) {
  //   return "loading ...";
  // }

  return (
    <div className="book_container">
      <img
        width={1920}
        height={1080}
        src={images.bookingBg.img}
        alt="booking bg"
        className="form_bg"
      />
      <div className="my_container">
        <div className="step1_sec">
          <form className="step1_form" onSubmit={handleSubmit}>
            <div className="form_field">
              <div className="form_title">{t("booking.dates")}</div>
              <RangePicker
                placeholder={[
                  t("booking.step1.startDate"),
                  t("booking.step1.endDate"),
                ]}
                variant="borderless"
                inputFontSize={40}
                className="range_picker"
                onChange={(date, datestring) => {
                  setDates(datestring);
                }}
                disabledDate={disabledDate}
                dropdownClassName="custom-range-picker-dropdown"
              />
              <p className="error_message">
                {formError.start_date || formError.end_date}
              </p>
            </div>
            <div className="form_field">
              <div className="form_title">{t("booking.step1.adults")}</div>
              <div className="adult_counter">
                <div className="countdown" onClick={handleAdultReduce}>
                  <span>-</span>
                </div>
                <input
                  type="text"
                  name="adultCount"
                  value={adults}
                  className="form_input adult_input"
                  style={{ pointerEvents: "none" }}
                />
                <div className="countup" onClick={handleAdultAdd}>
                  <span>+</span>
                </div>
              </div>
              <p className="error_message">{formError.adult}</p>
            </div>
            <button className="form_submit_btn" type="submit">
              {loading ? "Loading.." : t("booking.step1.showRates")}
            </button>

            <div className="conditions_wrap">
              <div className="cbox">
                <p className="title">
                  {t("booking.step1.extendedNotice.title1")}
                </p>
                <p className="caption">
                  {t("booking.step1.extendedNotice.caption1")}
                </p>
                <p className="caption">
                  {t("booking.step1.extendedNotice.caption2")}
                </p>
                <p
                  className="caption"
                  dangerouslySetInnerHTML={{
                    __html: t("booking.step1.extendedNotice.caption3"),
                  }}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Step1;
