import { useState, useEffect } from "react";

export const useReduceDescSize = (desc, length = 80) => {
  let reducedDesc;
  if (desc.length <= length) {
    reducedDesc = desc;
    return reducedDesc;
  } else {
    reducedDesc = desc.substring(0, length);
    reducedDesc = reducedDesc.substr(
      0,
      Math.min(reducedDesc.length, reducedDesc.lastIndexOf(" "))
    );
    reducedDesc += "...";
    return reducedDesc;
  }
};

export const useWidth = () => {
  const [Width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return Width;
};
