import React, { useEffect } from "react";
import "./rooms.scss";
import {
  abouticon,
  carpark,
  dishwasher,
  images,
  laundrymachine,
  leftarrow,
  rightarrow,
  swimmingpool,
  terrace,
} from "../../assets";
import { useLanguage } from "../../LanguageContext";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { useWidth } from "../../helpers/utils";

const Rooms = () => {
  const { t } = useLanguage();
  const width = useWidth();

  const amenitiesData = [
    {
      id: 1,
      img: images.bedroom.img,
      title: t("rooms.amenities.bedrooms.title"),
      desc: t("rooms.amenities.bedrooms.desc"),
    },
    {
      id: 2,
      img: images.modernbath.img,
      title: t("rooms.amenities.bathrooms.title"),
      desc: t("rooms.amenities.bathrooms.desc"),
    },
    {
      id: 3,
      img: images.lounges.img,
      title: t("rooms.amenities.lounges.title"),
      desc: t("rooms.amenities.lounges.desc"),
    },
    {
      id: 4,
      img: images.terrace.img,
      title: t("rooms.amenities.terrace.title"),
      desc: t("rooms.amenities.terrace.desc"),
    },
    {
      id: 5,
      img: images.pool.img,
      title: t("rooms.amenities.pool.title"),
      desc: t("rooms.amenities.pool.desc"),
    },
    {
      id: 6,
      img: images.kitchen.img,
      title: t("rooms.amenities.kitchen.title"),
      desc: t("rooms.amenities.kitchen.desc"),
    },
  ];

  const activitiesData = [
    {
      id: "01",
      img: images.foosball.img,
      title: t("rooms.activities.foosball"),
    },
    {
      id: "02",
      img: images.tabletennis.img,
      title: t("rooms.activities.tabletennis"),
    },
    {
      id: "03",
      img: images.barbecue.img,
      title: t("rooms.activities.barbecue"),
    },
    {
      id: "04",
      img: images.petanquecourt.img,
      title: t("rooms.activities.petanquecourt"),
    },
  ];

  const vacationData = [
    {
      id: 1,
      img: carpark,
      title: t("rooms.vacationplace.carpark"),
    },
    {
      id: 2,
      img: swimmingpool,
      title: t("rooms.vacationplace.swimmingpool"),
    },
    {
      id: 3,
      img: terrace,
      title: t("rooms.vacationplace.terrace"),
    },
    {
      id: 4,
      img: dishwasher,
      title: t("rooms.vacationplace.dishwasher"),
    },
    {
      id: 5,
      img: laundrymachine,
      title: t("rooms.vacationplace.laundrymachine"),
    },
  ];

  const galleryData = [
    {
      id: 1,
      img: images.gallery1.img,
    },
    {
      id: 2,
      img: images.gallery2.img,
    },
    {
      id: 3,
      img: images.gallery3.img,
    },
    {
      id: 4,
      img: images.gallery4.img,
    },
    {
      id: 5,
      img: images.gallery5.img,
    },
    {
      id: 6,
      img: images.gallery6.img,
    },
    {
      id: 7,
      img: images.gallery7.img,
    },
  ];

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <>
      <section className="rooms_sec1">
        <div className="my_container">
          <div className="text_container">
            <img
              width={25}
              height={50}
              src={abouticon}
              alt="leaf"
              className="small_icon"
              loading="lazy"
            />
            <div className="subtitle">{t("rooms.pagebanner.subtitle")}</div>
            <h2
              className="title"
              dangerouslySetInnerHTML={{ __html: t("rooms.pagebanner.title") }}
            />
          </div>
        </div>
        <div className="banner">
          <img
            width={1920}
            height={1244}
            src={
              width > 600 ? images.roomsbanner.img : images.roomsbannermb.img
            }
            alt="rooms"
            className="banner_img"
          />
        </div>
      </section>

      <section className="rooms_sec2">
        <div className="my_container">
          <div className="text_container">
            <h3 className="title">
              {t("rooms.amenities.textcontainer.title")}
            </h3>
            <p className="desc">{t("rooms.amenities.textcontainer.desc")}</p>
          </div>
          <div className="amenity_flex">
            {amenitiesData.map((data) => (
              <div className="amenity_box" key={`amenity-${data.id}`}>
                <div className="img_wrapper">
                  <img
                    width={566}
                    height={514}
                    src={data.img}
                    alt={data.title}
                    className="am_img"
                    loading="lazy"
                  />
                </div>
                <div className="text_container">
                  <h5 className="title">{data.title}</h5>
                  <p className="desc">{data.desc}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="rooms_sec3">
        <img
          width={1920}
          height={1080}
          src={images.activityBg.img}
          alt="activity bg"
          className="bg_img"
        />
        <div className="my_container">
          <div className="text_flex">
            <h5 className="title">
              {t("rooms.activities.textcontainer.title")}
            </h5>
            <p className="desc">{t("rooms.activities.textcontainer.desc")}</p>
          </div>
        </div>
        <div className="activity_slider">
          <Swiper
            modules={[Autoplay]}
            slidesPerView={2.5}
            spaceBetween={100}
            speed={1500}
            // allowTouchMove={false}
            centeredSlides
            loop
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            breakpoints={{
              0: {
                slidesPerView: 1.4,
                spaceBetween: 50,
                centeredSlides: false,
              },
              600: {
                slidesPerView: 2.2,
                spaceBetween: 50,
                centeredSlides: false,
              },
              992: {
                slidesPerView: 2.5,
                spaceBetween: 100,
                centeredSlides: true,
              },
            }}
          >
            {activitiesData.map((data) => (
              <SwiperSlide key={`activity-${data.id}`}>
                <div className="activity_box">
                  {data.id % 2 !== 0 && (
                    <div className="ac_text ac_first">
                      <div className="count">{data.id}</div>
                      <h6 className="title">{data.title}</h6>
                    </div>
                  )}
                  <div className="ac_img_wrap">
                    <img
                      width={658}
                      height={500}
                      src={data.img}
                      alt={data.title}
                      className="ac_img"
                      loading="lazy"
                    />
                  </div>
                  {data.id % 2 === 0 && (
                    <div className="ac_text ac_second">
                      <div className="count">{data.id}</div>
                      <h6 className="title">{data.title}</h6>
                    </div>
                  )}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </section>

      <section className="rooms_sec4">
        <div className="my_container">
          <div className="text_flex">
            <h5 className="title">
              {t("rooms.vacationplace.textcontainer.title")}
            </h5>
            <p className="desc">
              {t("rooms.vacationplace.textcontainer.desc")}
            </p>
          </div>
          <div className="vac_flex">
            {vacationData.map((data) => (
              <div className="vac_box" key={`vacation-${data.id}`}>
                <img
                  width={64}
                  height={64}
                  src={data.img}
                  alt="car park"
                  className="vac_icon"
                  loading="lazy"
                />
                <div className="title">{data.title}</div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="rooms_sec5">
        <div className="my_container">
          <h5 className="title">{t("rooms.interior.title")}</h5>
          <div className="gallery_slider">
            <Swiper
              modules={[Autoplay, Navigation, Pagination]}
              slidesPerView={1.6}
              spaceBetween={30}
              speed={1000}
              loop
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              pagination={{
                el: ".custom_pagination",
                type: "progressbar",
              }}
              navigation={{
                prevEl: ".gallery_slider .prev_icon",
                nextEl: ".gallery_slider .next_icon",
              }}
              breakpoints={{
                0: {
                  slidesPerView: 1.15,
                  spaceBetween: 20,
                },
                600: {
                  slidesPerView: 1.6,
                  spaceBetween: 30,
                },
              }}
            >
              {galleryData.map((data) => (
                <SwiperSlide key={`gallery-${data.id}`}>
                  <div className="img_wrapper">
                    <img
                      width={1050}
                      height={606}
                      src={data.img}
                      alt={data.id}
                      className="gallery_img"
                      loading="lazy"
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="nav_progress">
              <div className="nav_wrap">
                <div className="icon_wrap">
                  <img
                    width={84}
                    height={84}
                    src={leftarrow}
                    alt="previous"
                    className="nav_icon prev_icon"
                    loading="lazy"
                  />
                </div>
                <div className="icon_wrap">
                  <img
                    width={84}
                    height={84}
                    src={rightarrow}
                    alt="next"
                    className="nav_icon next_icon"
                    loading="lazy"
                  />
                </div>
              </div>
              <div class="swiper-pagination custom_pagination" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Rooms;
