import React, { useState } from "react";
import { useLanguage } from "../../../LanguageContext";
import config from "../../../config/config";
import http from "../../../helpers/http";
import Joi from "joi";
import { useBooking } from "../../../context/Booking/BookingState";
import FR_errors from "../../../joiLocales/validations_fr";

const Step3 = ({ currentStep }) => {
  // =============== VARIABLE & STATES  ===========================
  const [values, setValues] = useState({});
  const [formError, setFormError] = useState({});
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useLanguage();

  const currentLang = i18n.resolvedLanguage;

  const base_url = config.api_url + "/booking";
  const joiOptions = {
    abortEarly: false,
    allowUnknown: true,
    errors: {
      wrap: {
        label: "",
      },
      language: currentLang,
    },

    messages: {
      fr: { ...FR_errors },
    },
  };

  const Booking = useBooking();

  // =============== FUNCTIONS  ===========================

  const handleChange = (e) => {
    setValues((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };
  const validate = async (data) => {
    let schemaObj = {
      first_name: Joi.string().required().label(t("booking.step3.fname")),
      last_name: Joi.string().required().label(t("booking.step3.lname")),
      phone: Joi.number().required().label(t("booking.step3.contactno")),
      email: Joi.string()
        .email({ tlds: { allow: false } })
        .required()
        .label(t("booking.step3.emailid")),
      address: Joi.string().required().label(t("booking.step3.address")),
    };

    const schema = Joi.object(schemaObj).options({ allowUnknown: true });

    const { error } = schema.validate(data, joiOptions);

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    return errors ? errors : null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let form_values = values;

    let errors = await validate(form_values);

    setFormError(errors);
    if (Object.keys(errors).length) {
      return;
    }
    let status = currentStep + 1;

    setLoading(true);

    let api_data = { ...Booking.data, ...form_values };

    const { data } = await http.post(`${base_url}`, api_data);

    if (data) {
      Booking.update({ ...Booking.data, ...form_values, status: status });
      window.location.href = data.payment_url;
    }

    setLoading(false);

    // nextStep();
    window.scrollTo(0, 0);
  };

  // =============== HTML RENDERING  ===========================
  // =============== DATA FETCHING  ===========================
  // =============== SIDE EFFECTS  ===========================

  return (
    <div className="book_container">
      <div className="my_container">
        <div className="step3_sec">
          <div className="contact_wrap">
            <div className="title">{t("booking.step3.title")}</div>
            <form onSubmit={handleSubmit} noValidate>
              <div className="row">
                <div className="col-md-6">
                  <div className="form_field">
                    <div className="form_title">
                      {t("booking.step3.fname")}*
                    </div>
                    <input
                      type="text"
                      name="first_name"
                      value={values.first_name}
                      onChange={handleChange}
                      className="form_input"
                    />

                    <p className="error_message">{formError.first_name}</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form_field">
                    <div className="form_title">
                      {t("booking.step3.lname")}*
                    </div>
                    <input
                      type="text"
                      name="last_name"
                      value={values.last_name}
                      onChange={handleChange}
                      className="form_input"
                    />
                    <p className="error_message">{formError.last_name}</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form_field">
                    <div className="form_title">
                      {t("booking.step3.contactno")}*
                    </div>
                    <input
                      type="tel"
                      name="phone"
                      value={values.phone}
                      onChange={handleChange}
                      className="form_input"
                    />
                    <p className="error_message">{formError.phone}</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form_field">
                    <div className="form_title">
                      {t("booking.step3.emailid")}*
                    </div>
                    <input
                      type="email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      className="form_input"
                    />
                    <p className="error_message">{formError.email}</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form_field">
                    <div className="form_title">
                      {t("booking.step3.address")}*
                    </div>
                    <textarea
                      rows={3}
                      name="address"
                      value={values.address}
                      onChange={handleChange}
                      className="form_input"
                    />
                    <p className="error_message">{formError.address}</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form_field">
                    <div className="form_title">
                      {t("booking.step3.specialrequest")}
                    </div>
                    <textarea
                      rows={3}
                      name="special_request"
                      value={values.special_request}
                      onChange={handleChange}
                      className="form_input"
                    />
                    <p className="error_message">{formError.special_request}</p>
                  </div>
                </div>
              </div>
              <button className="form_submit_btn pay_btn">
                {loading ? "Loading.." : t("booking.step3.paynow")}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step3;
