import React, { useEffect, useState } from "react";
import "./booking.scss";
import StepWizard from "react-step-wizard";
import Step1 from "./BookingSteps/Step1";
import Step2 from "./BookingSteps/Step2";
import Step3 from "./BookingSteps/Step3";
import { stepcomplete } from "../../assets";
import { useLanguage } from "../../LanguageContext";

const Booking = () => {
  const [formStep, setFormStep] = useState(0);
  const [completedSteps, setCompletedSteps] = useState([]);
  const { t } = useLanguage();

  const handleStepChange = (newStep) => {
    if (newStep > formStep) {
      setCompletedSteps((prev) => [...prev, formStep]);
    } else {
      setCompletedSteps((prev) => prev.filter((step) => step < newStep));
    }
    setFormStep(newStep);
  };

  const renderStepIcon = (stepNumber) => {
    return completedSteps.includes(stepNumber - 1) ? (
      <img
        width={40}
        height={40}
        src={stepcomplete}
        alt="step done"
        className="done_icon"
      />
    ) : (
      stepNumber
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [formStep]);

  return (
    <>
      <section className="booking_sec">
        <div className="count_container">
          <div className="my_container">
            <div className="steps_strip">
              <div className="line_through" />
              <div className={`step_wrap ${formStep === 0 ? "active" : ""}`}>
                <div className="count">{renderStepIcon(1)}</div>
                <div className="title">{t("booking.dates")}</div>
              </div>
              <div className={`step_wrap ${formStep === 1 ? "active" : ""}`}>
                <div className="count">{renderStepIcon(2)}</div>
                <div className="title">{t("booking.rates")}</div>
              </div>
              <div className={`step_wrap ${formStep === 2 ? "active" : ""}`}>
                <div className="count">{renderStepIcon(3)}</div>
                <div className="title">{t("booking.details")}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="steps_container">
          <StepWizard
            transitions={{
              enterRight: "formChangeAnimation",
              enterLeft: "formChangeAnimation",
              intro: "formChangeAnimation",
            }}
            initialStep={formStep + 1}
          >
            <Step1  onStepChange={handleStepChange} />
            <Step2  onStepChange={handleStepChange} />
            <Step3  onStepChange={handleStepChange} />
          </StepWizard>
        </div>
      </section>
    </>
  );
};

export default Booking;
