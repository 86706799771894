import React from "react";
import "./animatedcta.scss";
import { Link } from "react-router-dom";
import { ctastar } from "../../assets";

export const AnimatedCta = ({
  url,
  urlText,
  newClass,
  lightTheme,
  target = "",
  icons,
}) => {
  return (
    <Link
      to={url}
      className={`animated-cta ${lightTheme && "light"} ${newClass}`}
      target={target}
    >
      {icons && (
        <img
          width={25}
          height={25}
          src={ctastar}
          alt="start"
          className="star"
        />
      )}
      <div className={`content ${icons && "withstar"}`}>
        <div className="text">{urlText}</div>
        <div className="hover-text">{urlText}</div>
      </div>
      {icons && (
        <img
          width={25}
          height={25}
          src={ctastar}
          alt="start"
          className="star star-inverse"
        />
      )}
    </Link>
  );
};
