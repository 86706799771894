import React, { useEffect, useState } from "react";
import { useLanguage } from "./LanguageContext";
import { langicon, langiconwhite, selectArrow, selectArrowWhite } from "./assets";
import { useWidth } from "./helpers/utils";

const options = [
  { key: 1, value: "fr", label: "French (FR)" },
  { key: 2, value: "en", label: "English (EN)" },
];

const LanguageSwitcher = ({ lightTheme }) => {
  const width = useWidth();
  const { handleLanguageChange } = useLanguage();
  const [selectedOption, setSelectedOption] = useState(
    localStorage.getItem("language") || options[0].value
  );

  useEffect(() => {
    handleLanguageChange(selectedOption);
  }, [selectedOption, handleLanguageChange]);

  const handleLanguage = (e) => {
    const selectedValue = e.target.value;
    setSelectedOption(selectedValue);
    handleLanguageChange(selectedValue);
  };

  return (
    <div className="language-switcher">
      <div className={`language_drop ${lightTheme && "light"}`}>
        <select
          className="language_select"
          value={selectedOption}
          onChange={handleLanguage}
        >
          {options.map((data) => (
            <option key={data.key} value={data.value}>
              {data.label}
            </option>
          ))}
        </select>

        <img
          width={12}
          height={8}
          src={lightTheme ? selectArrowWhite : selectArrow}
          alt=""
          className="select_arow"
        />
        {width <= 992 && (
          <div className="select_mask">
            <img
              width={16}
              height={16}
              src={lightTheme ? langiconwhite : langicon}
              alt="switch language"
              className="lang_icon"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default LanguageSwitcher;
