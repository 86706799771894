import React, { useEffect } from "react";
import "./location.scss";
import { AnimatedCta } from "../../components/AnimatedCta/AnimatedCta";
import { useLanguage } from "../../LanguageContext";
import {
  bookingURL,
  mapDirection,
  mapExternalURL,
} from "../../helpers/constant";
import { images, verticleline } from "../../assets";

const Location = () => {
  const { t } = useLanguage();

  const locationData = [
    {
      id: 1,
      img: images.location1.img,
      title: t("location.exoloreGrid.title1"),
      desc: t("location.exoloreGrid.desc1"),
    },
    {
      id: 2,
      img: images.location2.img,
      title: t("location.exoloreGrid.title2"),
      desc: t("location.exoloreGrid.desc2"),
    },
    {
      id: 3,
      img: images.location3.img,
      title: t("location.exoloreGrid.title3"),
      desc: t("location.exoloreGrid.desc3"),
    },
  ];

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <>
      <section className="loc_sec1">
        <div className="my_container">
          <div className="loc_banner_flex">
            <div className="text_container">
              <h1 className="subtitle">{t("location.subtitle")}</h1>
              <h2
                className="title"
                dangerouslySetInnerHTML={{ __html: t("location.title") }}
              />
              <p
                className="sub_desc"
                dangerouslySetInnerHTML={{ __html: t("location.subdesc") }}
              />
              <p className="desc">{t("location.desc")}</p>
              <AnimatedCta
                icons
                url={mapExternalURL}
                urlText={t("location.mapCta")}
                target="_blank"
              />
            </div>
            <div className="map_container">
              <iframe
                title="geo location"
                className="map_frame"
                src={mapDirection}
                width="100%"
                height="100%"
                allowfullscreen="false"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="loc_sec2">
        <div className="my_container">
          <h3 className="title">{t("location.exploreTitle")}</h3>
          <div className="beauty_flex">
            {locationData.map((data) => (
              <div className="beauty_box" key={`beauty-${data.id}`}>
                <div className="img_wrapper">
                  <img
                    width={566}
                    height={514}
                    src={data.img}
                    alt={data.title}
                    className="am_img"
                    loading="lazy"
                  />
                </div>
                <div className="text_container">
                  <h5 className="title">{data.title}</h5>
                  <p className="desc">{data.desc}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="invite_container">
            <h4 className="invite_title">{t("location.bookingStatement")}</h4>
            <img
              width={1}
              height={87}
              src={verticleline}
              alt="verticle"
              className="verticle_line"
              loading="lazy"
            />
            <AnimatedCta icons url={bookingURL} urlText={t("header.bookcta")} />
          </div>
        </div>
      </section>
    </>
  );
};

export default Location;
