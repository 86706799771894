import React, { useEffect } from "react";
import "./contactthankyou.scss";
import { useLanguage } from "../../LanguageContext";
import { AnimatedCta } from "../../components/AnimatedCta/AnimatedCta";
import { roomsURL } from "../../helpers/constant";
import { thankyouicon } from "../../assets";

function CThankyou() {
  const { t } = useLanguage();

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <>
      <section className="cthankyou">
        <div className="my_container">
          <div className="cthankyou_sec">
            <img
              width={100}
              height={100}
              src={thankyouicon}
              alt="cthankyou icon"
              className="cthankyou_icon"
            />
            <h1 className="title">{t("contact.thankyouMessage.message1")}</h1>
            <p
              className="caption caption2"
              dangerouslySetInnerHTML={{
                __html: t("contact.thankyouMessage.message2"),
              }}
            />
            <div className="cta_wrapper">
              <AnimatedCta
                icons
                urlText={t("contact.thankyouMessage.ctaText")}
                url={roomsURL}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default CThankyou;
