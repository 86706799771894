import React, { useEffect, useState } from "react";
import "./home.scss";
import { useLanguage } from "../../LanguageContext";
import { abouticon, brownstar, images, whiteverticleline } from "../../assets";
import { AnimatedCta } from "../../components/AnimatedCta/AnimatedCta";
import { bookingURL, roomsURL } from "../../helpers/constant";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Controller,
  EffectFade,
  Autoplay,
  Navigation,
  Thumbs,
  FreeMode,
} from "swiper/modules";

const Home = () => {
  const [firstSwiper, setFirstSwiper] = useState(null);
  const [secondSwiper, setSecondSwiper] = useState(null);
  const { t } = useLanguage();

  // DATA
  const amenitiesData = [
    {
      id: 1,
      title: t("home.amenities.activities"),
      bgImg: images.activitiesBg.img,
      thumb: images.activitiesThumb.img,
    },
    {
      id: 2,
      title: t("home.amenities.swimming"),
      bgImg: images.swimmingpoolBg.img,
      thumb: images.swimmingpoolThumb.img,
    },
    {
      id: 3,
      title: t("home.amenities.bedrooms"),
      bgImg: images.elegantbedroomsBg.img,
      thumb: images.elegantbedroomsThumb.img,
    },
    {
      id: 4,
      title: t("home.amenities.garden"),
      bgImg: images.grandgardenBg.img,
      thumb: images.grandgardenThumb.img,
    },
  ];

  // FUNCTIONS

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <>
      <section className="home_sec1">
        <video
          className="banner_video"
          poster={images.bannerPoster.img}
          src={images.homeVideo.img}
          loop
          muted
          autoPlay
          playsInline
        ></video>
        <div className="overlay" />
        <div className="banner_text">
          <h1
            className="banner_title"
            dangerouslySetInnerHTML={{ __html: t("home.banner.title") }}
          />
          <img
            width={1}
            height={78}
            src={whiteverticleline}
            alt="verticle"
            className="verticle"
          />
          <AnimatedCta
            icons
            urlText={t("home.banner.ctaText")}
            url={bookingURL}
          />
        </div>
      </section>

      <section className="home_sec2">
        <div className="my_container">
          <div className="about_grid">
            <div className="image_col1">
              <div className="small_img">
                <img
                  width={256}
                  height={320}
                  src={images.about1.img}
                  alt="about 1"
                  className="about_1"
                  loading="lazy"
                />
              </div>
              <img
                width={440}
                height={558}
                src={images.about2.img}
                alt="about 2"
                className="about_2"
                loading="lazy"
              />
            </div>
            <div className="center_col">
              <div className="text_container">
                <img
                  width={25}
                  height={50}
                  src={abouticon}
                  alt="about"
                  className="about_icon"
                  loading="lazy"
                />
                <h2
                  className="title"
                  dangerouslySetInnerHTML={{ __html: t("home.about.title") }}
                />
                <p
                  className="desc"
                  dangerouslySetInnerHTML={{ __html: t("home.about.desc") }}
                />
                <AnimatedCta
                  icons
                  url={roomsURL}
                  urlText={t("home.about.ctaText")}
                />
              </div>
            </div>
            <div className="image_col2">
              <img
                width={440}
                height={558}
                src={images.about3.img}
                alt="about 3"
                className="about_3"
                loading="lazy"
              />
              <div className="small_img">
                <img
                  width={256}
                  height={320}
                  src={images.about4.img}
                  alt="about 4"
                  className="about_4"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="peace_wrap">
          <img
            width={52}
            height={52}
            src={brownstar}
            alt="brown star"
            className="star_icon"
            loading="lazy"
          />
        </div>
        <div className="peace_content">
          <div className="my_container">
            <div className="text_container">
              <h3 className="title">{t("home.peace.title")}</h3>
              <p className="desc">{t("home.peace.desc")}</p>
            </div>
          </div>
        </div>
      </section>

      <section className="home_sec3">
        <div className="bg_wrapper">
          <Swiper
            effect={"fade"}
            spaceBetween={0}
            speed={2000}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            modules={[
              EffectFade,
              Navigation,
              Thumbs,
              Autoplay,
              FreeMode,
              Controller,
            ]}
            allowTouchMove={false}
            onSwiper={setFirstSwiper}
            controller={{ control: secondSwiper }}
            loop={true}
            className="bg_slider"
          >
            {amenitiesData.map((data) => (
              <SwiperSlide key={`amenitybg-${data.id}`} data-id={data.id}>
                <img
                  width={1920}
                  height={920}
                  src={data.bgImg}
                  alt={data.title}
                  className="bg_img"
                />
                <div className="title_wrapper">
                  <div className="title_slider">
                    <h4 className="amenity_title">{data.title}</h4>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          {/* <div className="title_wrapper">
            <Swiper
              spaceBetween={0}
              modules={[Controller]}
              onSwiper={setSecondSwiper}
              controller={{ control: firstSwiper && thirdSwiper }}
              loop={true}
              allowTouchMove={false}
              className="title_slider"
            >
              {amenitiesData.map((data) => (
                <SwiperSlide
                  key={`amenityTitle-${data.id}`}
                  className="title_slide"
                >
                  <h4 className="amenity_title">{data.title}</h4>
                </SwiperSlide>
              ))}
            </Swiper>
          </div> */}
        </div>
        <div className="thumb_wrapper">
          <Swiper
            spaceBetween={0}
            modules={[Controller]}
            onSwiper={setSecondSwiper}
            controller={{ control: firstSwiper }}
            loop={true}
            allowTouchMove={false}
            className="thumb_slider"
          >
            {amenitiesData.map((data) => (
              <SwiperSlide key={`amenity-${data.id}`} className="thumb_slide">
                <img
                  width={339}
                  height={438}
                  src={data.thumb}
                  alt={data.title}
                  className="thumb_img"
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        <main></main>
      </section>

      <section className="home_sec4">
        <div className="my_container">
          <div className="image_text_grid">
            <div className="img_box">
              <img
                width={640}
                height={840}
                src={images.feature1.img}
                alt="feature 1"
                className="feature_img"
                loading="lazy"
              />
            </div>
            <div className="content_box">
              <div className="text_container">
                <h6 className="subtitle">
                  {t("home.fetaureGrid.box1.subtitle")}
                </h6>
                <h4 className="title">{t("home.fetaureGrid.box1.title")}</h4>
                <p className="desc">{t("home.fetaureGrid.box1.desc")}</p>
              </div>
            </div>
          </div>
          <div className="image_text_grid reverse">
            <div className="content_box">
              <div className="text_container">
                <h6 className="subtitle">
                  {t("home.fetaureGrid.box2.subtitle")}
                </h6>
                <h4 className="title">{t("home.fetaureGrid.box2.title")}</h4>
                <p className="desc">{t("home.fetaureGrid.box2.desc")}</p>
              </div>
            </div>
            <div className="img_box">
              <img
                width={641}
                height={855}
                src={images.feature2.img}
                alt="feature 2"
                className="feature_img"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
