import axios from "axios";
// import { toast } from "react-toastify";

import i18next from "i18next";

import { toast } from "react-toastify";
console.log("from axios",localStorage.getItem("language"));
// axios.defaults.headers.common['Accept-Language']=i18next.resolvedLanguage
// axios.defaults.headers.common['Accept-Language']=localStorage.getItem("language")

axios.interceptors.request.use(function (config) {
 
  // config.headers["Accept-Language"] =  localStorage.getItem("language");
  config.headers["Accept-Language"] =  "fr";
   
  return config;
});
const http = {
  get: async (url,params={}) => {
    try {
      const response = await axios.get(url,{params});

      const { data } = response;

      if (data.status === 200) {
        return data;
      }

      if (data.status >= 400 || data.status <= 499) {
        toast.error(data.message);

        return null;
      }
    } catch (error) {
      // notification["error"]({
      //   message: "Internal Server Error",
      // });

      toast.error("Internal Server Error");
      console.log("error", error.response);
      //   return false;
    }
  },
  post: async (url, request) => {
    try {
      const response = await axios.post(url, request);

      const { data } = response;
      // console.log(data);
      if (data.status === 200) {
        return data;
      }

      if (data.status >= 400 || data.status <= 499) {
        toast.error(data.message);

        return null;
      }
    } catch (error) {
      toast.error("Internal Server Error");

      console.log("error", error.response);
      //   return false;
    }
  },

  customPost: async (url, request) => {
    try {
      const response = await axios.post(url, request);

      const { data } = response;
      // console.log(data);
      if (data.status === 200) {
        return data;
      }

      if (data.status >= 400 || data.status <= 499) {
        // toast.error(data.message);

        return data;
      }
    } catch (error) {
      toast.error("Internal Server Error");

      console.log("error", error.response);
      //   return false;
    }
  },

  put: async (url, request) => {
    try {
      const response = await axios.put(url, request);

      const { data } = response;

      if (data.status === 200) {
        return data;
      }

      if (data.status >= 400 || data.status <= 499) {
        toast.error(data.message);

        return null;
      }
    } catch (error) {
      toast.error("Internal Server Error");

      console.log("error", error.response);
      //   return false;
    }
  },
  delete: async (url) => {
    try {
      const response = await axios.delete(url);

      const { data } = response;

      if (data.status === 200) {
        return data;
      }

      if (data.status >= 400 || data.status <= 499) {
        toast.error(data.message);

        return null;
      }
    } catch (error) {
      toast.error("Internal Server Error");

      console.log("error", error.response);
      //   return false;
    }
  },
};
export default http;
