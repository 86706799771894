import React from "react";
import { useLanguage } from "../../../LanguageContext";
import { useBooking } from "../../../context/Booking/BookingState";
import moment from "moment";
import { useSetting } from "../../../context/Setting/SettingState";

// const selectedData = {
//   duration: "4",
//   startDay: "Tue",
//   startDate: "03/12/2024",
//   endDay: "Sat",
//   endDate: "07/12/2024",
//   adultsCount: "4",
// };

const Step2 = ({ currentStep, previousStep, nextStep, onStepChange }) => {
  // =============== VARIABLE & STATES  ===========================

  const { t } = useLanguage();

  const Booking = useBooking();
  const Setting = useSetting();

  const per_day_amount = Setting.data.price;
  const start_date = moment(Booking.data.start_date);
  const end_date = moment(Booking.data.end_date);
  const guest = Booking.data.guest;

  const date_format = "ddd DD/MM/YYYY";
  const num_of_nights = end_date.diff(start_date, "days") + 1;
  const amount = per_day_amount * num_of_nights;

  // =============== FUNCTIONS  ===========================

  const handlePrev = () => {
    onStepChange(currentStep - 2);
    previousStep();
  };

  const handleSubmit = async () => {
    let form_values = {
      amount: (amount/2).toFixed(),
    };

    let status = currentStep + 1;

    Booking.update({ ...Booking.data, ...form_values, status: status });

    nextStep();
    onStepChange(currentStep);
    window.scrollTo(0, 0);
  };

  // =============== HTML RENDERING  ===========================
  // =============== DATA FETCHING  ===========================
  // =============== SIDE EFFECTS  ===========================

  return (
    <div className="book_container">
      <div className="my_container">
        <div className="step2_sec">
          <div className="step2_left">
            <div className="sldates_wrap">
              <div className="sl_details">
                <div className="sl_dates">
                  <p className="title">
                    {t("booking.step2.nights")} {num_of_nights}
                  </p>
                  <p className="dates">
                    {start_date.format(date_format)} {t("booking.step2.to")}{" "}
                    {end_date.format(date_format)}
                  </p>
                </div>
                <div className="sl_adult">
                  <p className="title">{t("booking.step2.guests")}</p>
                  <p className="adult">
                    {t("booking.step2.adults")} {guest}
                  </p>
                </div>
              </div>
              <div className="sl_edit">
                <button className="edit_btn" onClick={handlePrev}>
                  {t("booking.step2.edit")}
                </button>
              </div>
            </div>
            <div className="rates_wrap">
              <p className="title">{t("booking.step2.nonrefund")}</p>
              <p className="note">{t("booking.step2.notice")}</p>
              <div className="ad_bal_flex">
                <p className="rate_ad">
                  {t("booking.step2.advance")}: <br />
                  {(amount/2).toFixed()} €
                </p>
                <p className="rate_ad">
                  {t("booking.step2.balance")}: <br />
                  {per_day_amount} €
                </p>
              </div>
              <p className="rate">
                {t("booking.step2.total")}: {amount} €
              </p>
              <button onClick={handleSubmit} className="form_submit_btn">
                {t("booking.step2.booknow")}
              </button>
            </div>
          </div>
          <div className="step2_right">
            <div className="conditions_wrap">
              <div className="cbox">
                <p className="title">
                  {t("booking.step2.extendedNotice.title1")}
                </p>
                <p className="caption">
                  {t("booking.step2.extendedNotice.caption1")}
                </p>
              </div>
              <div className="cbox">
                <p className="title">
                  {t("booking.step2.extendedNotice.title2")}
                </p>
                <p
                  className="caption"
                  dangerouslySetInnerHTML={{
                    __html: t("booking.step2.extendedNotice.caption2"),
                  }}
                />
              </div>
              {/* <div className="cbox">
                <p className="title">
                  {t("booking.step2.extendedNotice.title3")}
                </p>
                <p className="caption">
                  {t("booking.step2.extendedNotice.caption3")}
                  <br />
                  <br />
                  {t("booking.step2.extendedNotice.caption4")}
                </p>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step2;
