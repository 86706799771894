import React, { useEffect, useState } from "react";
import "./thankyou.scss";
import BookingConfirmed from "../../../components/BookingConfirmed/BookingConfirmed";
import http from "../../../helpers/http";
import { useParams } from "react-router-dom";
import config from "../../../config/config";

const Thankyou = () => {
  // =============== VARIABLE & STATES  ===========================

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  const { id: order_id } = useParams();
  const base_url = config.api_url + "/booking/info";

  // =============== FUNCTIONS  ===========================
  // =============== HTML RENDERING  ===========================
  // =============== DATA FETCHING  ===========================

  const fetchData = async (order_id) => {
    setLoading(true);
    const { data } = await http.get(`${base_url}/${order_id}`);

    if (data) {
      setData(data);
    }
    setLoading(false);
  };

  // =============== SIDE EFFECTS  ===========================

  useEffect(() => {
    fetchData(order_id);
    window.scroll(0, 0);
  }, []);

  if (loading) {
    return "Loading..";
  }

  return (
    <section className="thankyou">
      <div className="my_container">
        <BookingConfirmed data={data} />
      </div>
    </section>
  );
};

export default Thankyou;
