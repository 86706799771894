import React, { useContext, useState } from "react";

import BookingContext from "./BookingContext";

const BookingState = (props) => {
  const [data, setData] = useState({});

  const update = (data) => {
    setData(data);
  };
  return (
    <BookingContext.Provider value={{ data, update }}>
      {props.children}
    </BookingContext.Provider>
  );
};


export const useBooking = () => {
  return useContext(BookingContext);
};

export default BookingState;
