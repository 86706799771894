import React from "react";
import "./notfound.scss";
import { images } from "../../assets";
import { useLanguage } from "../../LanguageContext";
import { AnimatedCta } from "../../components/AnimatedCta/AnimatedCta";
import { bookingURL } from "../../helpers/constant";

function NotFound() {
  const { t } = useLanguage();

  return (
    <>
      <section className="notfound">
        <div className="my_container">
          <div className="notfound_sec">
            <img
              width={100}
              height={100}
              src={images.failed.img}
              alt="notfound icon"
              className="notfound_icon"
            />
            <h1 className="title">404</h1>
            {/* <p
              className="caption caption2"
              dangerouslySetInnerHTML={{ __html: t("booking.transMessage") }}
            /> */}
            <div className="cta_wrapper">
              <AnimatedCta
                icons
                urlText={t("notfound")}
                url={bookingURL}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default NotFound;
